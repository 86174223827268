import ReactPlayer, { type ReactPlayerProps } from "react-player";

export function WistiaVideoPlayer(props: ReactPlayerProps) {
  return (
    <ReactPlayer
      width="160px"
      height="90px"
      config={{
        wistia: {
          options: {
            wistiaPopover: true,
          },
        },
      }}
      {...props}
    />
  );
}
