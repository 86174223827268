import { Notifications } from "@flpstudio/design-system";
import { Profiler, captureException, captureMessage } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { StrictMode, useEffect, useState } from "react";

import { amplitudeExperiments } from "@/third-party/amplitude-experiments";
import { showNotification } from "@/utils/notification";
import { AuthProvider } from "./providers/AuthProvider";
import { RemixedRoutes } from "./routes/router";

axios.defaults.baseURL = `${import.meta.env.VITE_API_BASE_URL}/v1`;
axios.defaults.withCredentials = true;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      onError: () => {
        showNotification({
          title: "Sorry we couldn't complete your request",
          message: "Please try again in a moment.",
          color: "error",
        });
      },
    },
  },
});

const App = () => {
  const [isFeatureFlagInitialized, setIsFeatureFlagInitialized] =
    useState(false);

  useEffect(() => {
    amplitudeExperiments.init().then(() => {
      setIsFeatureFlagInitialized(true);
    });
    if (window) {
      try {
        // https://vitejs.dev/guide/build.html#load-error-handling
        const reloadedKey = "reloadForPreLoadErr";
        window.sessionStorage.removeItem(reloadedKey);

        const preloadErrorHandler = (event: Event) => {
          event.preventDefault();
          if (!window.sessionStorage.getItem(reloadedKey)) {
            window.location.reload();
            window.sessionStorage.setItem(reloadedKey, "reloaded");
            captureMessage("preloadError handler triggered");
          } else {
            captureMessage(
              "preloadError handler triggered but chunk still failed to load.",
            );
          }
        };

        window.addEventListener("vite:preloadError", preloadErrorHandler);

        return () => {
          window.removeEventListener("vite:preloadError", preloadErrorHandler);
        };
      } catch (error) {
        captureException(error);
      }
    }
  }, []);

  if (!isFeatureFlagInitialized) {
    return null;
  }

  return (
    <StrictMode>
      <Profiler>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Notifications />
            <RemixedRoutes />
          </AuthProvider>
        </QueryClientProvider>
      </Profiler>
    </StrictMode>
  );
};

export { App };
