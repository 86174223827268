import { SimpleGrid, Stack, Text } from "@flpstudio/design-system";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { CardCheckbox } from "@/components/molecules/CardCheckbox/CardCheckbox";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import {
  useExpertTechnicalAreas,
  useTechnicalAreas,
} from "@/hooks/use-technical-areas";
import { useExpertTechnicalAreasMutation } from "@/hooks/use-technical-areas-mutation";

type Props = {
  className?: string;
};

export function ExpertTechnicalAreasForm(props: Props) {
  const technicalAreas = useTechnicalAreas();
  const expertTechnicalAreas = useExpertTechnicalAreas();
  const expertTechnicalAreasMutation = useExpertTechnicalAreasMutation();

  if (technicalAreas.isLoading || expertTechnicalAreas.isLoading) {
    return <Skeleton />;
  }

  if (technicalAreas.isError || expertTechnicalAreas.isError) {
    return <ComponentError />;
  }

  const expertTechnicalAreasIds = expertTechnicalAreas.data?.map(
    (area) => area.id,
  );

  return (
    <form name="expertTechnicalAreas" className={props.className}>
      <Stack className="h-full">
        <Text className="text-[--mantine-color-gray-7]">
          Please select areas in which you are most comfortable assisting
          clients.
        </Text>
        <SimpleGrid
          cols={{ base: 1, lg: 2 }}
          component="ul"
          className="m-0 list-none p-0"
        >
          {technicalAreas.data?.map((area) => (
            <li key={area.id}>
              <CardCheckbox
                value={area.id}
                label={area.name}
                defaultChecked={expertTechnicalAreasIds?.includes(area.id)}
                onChange={(event) =>
                  expertTechnicalAreasMutation.mutate({
                    action: event.target.checked ? "put" : "delete",
                    technicalAreaId: area.id,
                  })
                }
              />
            </li>
          ))}
        </SimpleGrid>
      </Stack>
    </form>
  );
}
