import { Button, Group, Stack, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { TechnicalAreasList } from "@/components/molecules/TechnicalAreasList/TechnicalAreasList";
import { ComponentError } from "@/components/organisms/Error/ComponentError";
import { ExpertTechnicalAreasForm } from "@/components/organisms/ExpertTechnicalAreasForm/ExpertTechnicalAreasForm";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { useExpertTechnicalAreas } from "@/hooks/use-technical-areas";
import { Amplitude, ExpertOwnProfileEvent } from "@/third-party/amplitude";

export function ExpertTechnicalAreas() {
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("technicalAreas"));

  const { data, isLoading, isError, refetch } = useExpertTechnicalAreas();

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <ComponentError />;
  }

  function handleClose() {
    handlers.close();
    refetch();
    Amplitude.track(ExpertOwnProfileEvent.name, {
      ...ExpertOwnProfileEvent.properties.technicalAreasModalClose,
    });
  }

  return (
    <Stack>
      <Group className="justify-between">
        <Title order={1} className="text-xl">
          Technical expertise
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            Amplitude.track(ExpertOwnProfileEvent.name, {
              ...ExpertOwnProfileEvent.properties.editTechnicalAreas,
            });
            handlers.open();
          }}
        >
          Edit
        </Button>
      </Group>
      {data?.length ? (
        <TechnicalAreasList items={data} />
      ) : (
        <Text className="text-[--mantine-color-gray-7]">
          Please add your areas of technical expertise. This information will
          help us match you with potential clients.
        </Text>
      )}
      <Drawer
        opened={opened}
        onClose={handleClose}
        title="Add your technical expertise"
      >
        <Stack className="h-full">
          <ExpertTechnicalAreasForm className="grow" />
          <div className="-mb-6 sticky bottom-0 z-10 mt-auto bg-white pb-6 lg:text-right">
            <Button
              variant="outline"
              onClick={handleClose}
              className="w-full lg:w-auto"
            >
              Close
            </Button>
          </div>
        </Stack>
      </Drawer>
    </Stack>
  );
}
