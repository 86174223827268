import { Button, Group, Stack } from "@flpstudio/design-system";
import { useState } from "react";

import type { TechnicalAreaDto } from "@/api/openapi";

const DEFAULT_EXPANDED_ITEMS_COUNT = 10; // Around two lines of text on large screens

type Props = {
  defaultExpandedItemsCount?: number;
  items: TechnicalAreaDto[];
};

export function TechnicalAreasList({
  defaultExpandedItemsCount = DEFAULT_EXPANDED_ITEMS_COUNT,
  items,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const listItems = items
    .slice(0, expanded ? undefined : defaultExpandedItemsCount)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Stack>
      <Group component="ul" className="m-0 list-none p-0">
        {listItems.map((area) => (
          <li
            key={area.id}
            className="flex h-10 items-center rounded border border-[--mantine-color-gray-3] border-solid bg-[--mantine-color-gray-2] px-2"
          >
            <span className="line-clamp-1 text-[--mantine-color-gray-7]">
              {area.name}
            </span>
          </li>
        ))}
      </Group>
      {!expanded && items.length > defaultExpandedItemsCount && (
        <Button
          variant="transparent"
          className="self-start border-none p-0 hover:underline"
          onClick={() => setExpanded(true)}
        >
          Expand all ({items.length})
        </Button>
      )}
    </Stack>
  );
}
